html {
    height: 100%;
}
@font-face {
  font-family: "Wilma";
  src: url('./img/wilma.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Cochin";
  src: url('./img/cochin.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura PT";
  src: url('./img/futura-pt.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Futura PT";
  src: url('./img/futura-pt-medium.woff2') format('woff2');
  font-weight: 450;
  font-style: normal;
}
@font-face {
  font-family: "Futura PT";
  src: url('./img/futura-pt-bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: Cochin, Futura PT,-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Wilma",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

button{
  font-family: "Wilma", Futura PT;font-size: 14px;font-weight: 450;line-height: 20px;letter-spacing: 0.15em;text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-flags{
  margin-top:20px;
}
.menu-flags-button {
  border: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  padding: 0;
}

.menu-flags ul {
  border: 1px solid #000 !important;
  border-radius: 4px !important;
  margin-top: 5px !important;
}

div[class*="ReactFlagsSelect-module_flagsSelect"]{
  padding:0px!important;
}
button[class*="ReactFlagsSelect-module_selectBtn"]:after{
border:none!important;
}
div[class*="ReactFlagsSelect-module_flagsSelect"] button span{
  text-transform: uppercase;
}


.menu-flags .flag-select__option {
  padding: 5px 10px !important;
  @media (max-width: 768px) {
    padding: 0px !important;
  }
}

span[class*="ReactFlagsSelect-module_selectFlag"] {
  display: none;
}

.menu-flags .flag-select__option:hover {
  background-color: #f0f0f0 !important;
}

.main-banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 20px;
}

#card-items-container > div{
  @media (min-width: 600px) {
    margin: 0;
  }
  margin: auto;
}